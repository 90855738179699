<template>
	<div class="login-wrapper">
		<div style="padding: 80px 80px;box-shadow: 0px 0px 40px rgba(39, 148, 175, 0.1);">
			<span class="cA1 d-block fs8 fwbold bbbule2 pb5" style="display: inline-block;margin-bottom: 20px;">
			  绑定账号
			</span>
			<el-form :model="ruleForm" status-icon ref="ruleForm" label-width="60px" class="demo-ruleForm">
				<el-form-item label="手机号" prop="pass">
					<el-input type="text" v-model="ruleForm.username" autocomplete="off" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="checkPass">
					<el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="请输入密码"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
					<el-button @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
		
	</div>
</template>

<script>
	import {
		bindUser
	} from "@/api/login.js";
	export default {
		name: "bindCount",
		data() {
			return {
				ruleForm: {
					username: '',
					password: '',
					userId: 0,
				}
			};
		},
		async created() {},
		mounted() {
			this.ruleForm.userId = this.$route.query.userId
		},
		created() {

		},
		methods: {
			submitForm() {
				var that = this;
				bindUser(this.ruleForm).then(res => {
					this.$message({
						message: '绑定成功！',
						type: 'success'
					});
					setInterval(function() {
						that.$router.push({
							path: '/login'
						})
					}, 1000)
				}).catch(res => {
					this.$message.error(res.message);
					
				})
			},
			resetForm() {
				this.ruleForm = {
					username: '',
					password: '',
					userId: 0
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	.login-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		background-image: url("../../assets/images/login/login_bg.png");
		background-size: cover;
		display: flex;
		flex-direction: row;
		z-index: 1;
		justify-content: center;
		align-items: center;
	}
</style>
